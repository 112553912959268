@import '../../variables';

.globalloader-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    z-index: $z-loader;
    background-color: white;
    position: absolute;

    .loading-text {
        margin: 0 10px;
    }
}

.spinnerloader-container{
    margin-top: 25%;
    width: 100%;
    text-align: center;

    .loading-text {
        margin: 0 10px;
    }

    .spinner-border {
        --bs-spinner-width: 5rem;
        --bs-spinner-height: 5rem;
    }


}

@import '../../_variables.scss';

.navbar-main {
    background-color: #fdfdfd;
    position: relative;
    display: flex;
    flex: 1;
    .navbar-nav-desktop {
        width: $header-link-block;
    }
    .nav-button {
        text-align: start;
        background-color: inherit;
        width: fit-content;
        border: none;
        padding: 0;
        margin: 0px;
    }
    .nav-dropdown-button .dropdown-menu {
        display: none;
    }
    #navbarScrollingDropdownOne::after,
    #navbarScrollingDropdownTwo::after {
        content: none;
    }
    #navbarScrollingDropdownOne,
    #navbarScrollingDropdownTwo,
    .nav-link {
        padding: 6px 0 0 0 !important;
        color: black;
    }
    #navbarScrollingDropdownOne:hover,
    #navbarScrollingDropdownTwo:hover,
    .nav-link:hover {
        text-decoration: 2px underline solid $primary-background-light;
        text-underline-offset: 5px;
        color: black;
    }
    .nav-dropdown {
        width: 100vw;
        z-index: $z-nav-dropdown-desktop;
        .nav-links {
            text-decoration: none;
            width: fit-content;
        }
        .nav-links:hover {
            text-decoration: 2px underline solid $primary-background-light;
            text-underline-offset: 5px;
        }
    }
    .cart-profile {
        width: $header-link-block;
        .cart-profile-inner-div {
            width: 80px;
            .profile-icon {
                cursor: pointer;
            }
        }
    }
}

.nav-link {
    padding: 6px 0 0 0;
}

.nav-links:link,
.nav-links:visited,
.nav-links:hover,
.nav-links:active {
    color: black;
}

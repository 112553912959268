@import '../../variables';

.toaster-main {
    z-index: $z-toaster;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background: #f9c78c;
    height: 65px;
    min-height: 45px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100wh;
    box-shadow: 10px 10px 20px #000;

    .alert-primary {
        background: #f9c78c;
        border: 0;
    }

    .alert-dismissible {
        padding: 0 2rem;
        margin: 0;

        .btn-close {
            opacity: 2;
            position: unset;
            padding: 0.2rem;
            font-size: $px-13;
        }

        .check-out-button {
            padding-top: 0.3rem;
            font-weight: bold;

            letter-spacing: 0.11em;
            text-transform: uppercase;
            font-size: $px-14;
            color: #000000;
        }
    }
}

/* Global Variables */

//Font family
$font-family-light: 'BeatriceLight';
$font-family-regular: 'BeatriceRegular';

//percent to em conversion
$percent-6: 0.06em;
$percent-11: 0.11em;

//Responsive screen dimensions variables
$large-desktop-screen: 1440px;
$desktop-screen: 1024px;
$tablet-screen: 768px;
$mobile-screen: 576px;
$sub-mobile-screen: 425px;

// Header link region width
$header-link-block: 252px;

// Header heights
$header-desktop: 59px;
$header-mobile-tablet: 56px;

//Logo fade out delay variable
$fade-out-delay: 500ms;
$theme-font-color: #f4f0ec;
$background-color: #f4f0ec;
$grey: grey;
$white: #ffffff;
$primary-text-color: #000000;
$primary-background-light: #7fb4bf;
$primary-background-color: #98c6d0;
$secondary-background-color: #f4f0ec;

// large z - Index for various components
$z-toaster: 3000; //toasterStyle.scss
$z-loader: 2005; //loader.scss
$z-cart: 2002; //cart.scss
$z-header-wrap: 2001; //header.scss
$z-popupWindow: 2000; //popupWindow.scss
$z-popup: 2001; //popup.scss
$z-orderSummary: 1100;
$z-signup-backdrop: 2500; //signupPage.scss
$z-signup: 2500; //signupPage.scss
$z-profile-account-container: 1000; // profileView.scss
$z-subscription-fixed-footer: 20; // subscriptionFooter.scss
$z-social-footer: 15; // footer.scss
$z-nav-dropdown-desktop: 40; // UpdatedHeaderDesktop.scss
$z-product-feature-icons: 20; // productDetailsStyle.scss
$z-gift-datepicker: 20; // giftcard-recipient.scss

//REM equalivalent to PX - CSS units considaring 16px as root pixel
$px-1: 0.063rem;
$px-2: 0.125rem;
$px-3: 0.188rem;
$px-4: 0.25rem;
$px-5: 0.313rem;
$px-6: 0.375rem;
$px-7: 0.438rem;
$px-8: 0.5rem;
$px-9: 0.563rem;
$px-10: 0.625rem;
$px-11: 0.688rem;
$px-12: 0.75rem;
$px-13: 0.813rem;
$px-14: 0.875rem;
$px-15: 0.938rem;
$px-16: 1rem;
$px-17: 1.063rem;
$px-18: 1.125rem;
$px-19: 1.188rem;
$px-20: 1.25rem;
$px-21: 1.313rem;
$px-22: 1.375rem;
$px-23: 1.438rem;
$px-24: 1.5rem;
$px-25: 1.563rem;
$px-26: 1.625rem;
$px-27: 1.688rem;
$px-28: 1.75rem;
$px-29: 1.813rem;
$px-30: 1.875rem;
$px-32: 2rem;
$px-34: 2.125rem;
$px-36: 2.25rem;
$px-40: 2.5rem;
$px-42: 2.625rem;
$px-44: 2.75rem;
$px-45: 2.813rem;
$px-48: 3rem;
$px-56: 3.5rem;
$px-90: 5.625rem;

// @import '../../variables';

@font-face {
    font-family: 'BeatriceLight';
    src: url(./fonts/Beatrice-Light.ttf);
}

@font-face {
    font-family: 'BeatriceRegular';
    src: url(./fonts/Beatrice-Regular.woff2);
}

body {
    font-family: 'BeatriceLight', 'BeatriceRegular';
    letter-spacing: 0.06em;

    .cursor-pointer {
        cursor: pointer;
    }
}

body::-webkit-scrollbar {
    display: none;
}

input {
    height: auto;
}

.App {
    background-color: #fbf8f4;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.center-me {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

@import '../../variables';

.section-main {
    top: 35% !important;

    & .error-title {
        font-size: calc($px-42 + 4.5vw);
        margin-bottom: 50px;
    }

    & .error-title-desktop {
        font-size: calc($px-42 + 2.5vw);
        margin-bottom: 40px;
    }

    & .error-top-margin {
        margin-top: 100px;
    }

    & .error-top-margin-desktop {
        margin-top: 70px;
    }

    & .nav-title {
        border-bottom: 1px solid #5a8d97;
        color: black;
        margin-right: 5px;
    }
}

.message-container {
    display: flex;
    justify-content: center;
    align-items: center;
    // min-height: 200px;
    min-height: calc(100vh - 380px);
}
